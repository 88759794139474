// extracted by mini-css-extract-plugin
export var brochures = "ProviderShowContentBrochures__brochures__FNVcU";
export var brochuresTitle = "ProviderShowContentBrochures__brochuresTitle__bbvHs";
export var column = "ProviderShowContentBrochures__column__s0mOS";
export var flex = "ProviderShowContentBrochures__flex__h4wj7";
export var flexColumn = "ProviderShowContentBrochures__flexColumn__YP4xt";
export var gap1 = "ProviderShowContentBrochures__gap1__nbNDo";
export var gap2 = "ProviderShowContentBrochures__gap2__i142y";
export var gap3 = "ProviderShowContentBrochures__gap3__CrMsg";
export var gap4 = "ProviderShowContentBrochures__gap4__tqkKw";
export var gap5 = "ProviderShowContentBrochures__gap5___oYyb";
export var noBrochures = "ProviderShowContentBrochures__noBrochures__ZamgK";
export var row = "ProviderShowContentBrochures__row__kmA9k";