// extracted by mini-css-extract-plugin
export var certification = "ProviderShowContentCertifications__certification__S8gdg";
export var certificationLabelContainer = "ProviderShowContentCertifications__certificationLabelContainer__LUzIG";
export var certificationTitle = "ProviderShowContentCertifications__certificationTitle__rGzxV";
export var certificationTooltip = "ProviderShowContentCertifications__certificationTooltip__Yeefp";
export var certifications = "ProviderShowContentCertifications__certifications__vXeNU";
export var certificationsRow = "ProviderShowContentCertifications__certificationsRow__gTwJn";
export var certificationsTitle = "ProviderShowContentCertifications__certificationsTitle__MivaM";
export var column = "ProviderShowContentCertifications__column__AZndf";
export var flex = "ProviderShowContentCertifications__flex__OSxdO";
export var flexColumn = "ProviderShowContentCertifications__flexColumn__I2Rna";
export var gap1 = "ProviderShowContentCertifications__gap1__fSgAK";
export var gap2 = "ProviderShowContentCertifications__gap2___K5tG";
export var gap3 = "ProviderShowContentCertifications__gap3___5Om5";
export var gap4 = "ProviderShowContentCertifications__gap4__fnGUF";
export var gap5 = "ProviderShowContentCertifications__gap5__lLvvt";
export var noCertifications = "ProviderShowContentCertifications__noCertifications__ywueh";
export var row = "ProviderShowContentCertifications__row__y9b2i";