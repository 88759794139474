// extracted by mini-css-extract-plugin
export var column = "ProviderShowLocation__column__EA5q4";
export var flex = "ProviderShowLocation__flex__uC9rO";
export var flexColumn = "ProviderShowLocation__flexColumn__drHIF";
export var gap1 = "ProviderShowLocation__gap1__ukYpe";
export var gap2 = "ProviderShowLocation__gap2__ffpYH";
export var gap3 = "ProviderShowLocation__gap3__XgIdl";
export var gap4 = "ProviderShowLocation__gap4__aNSz8";
export var gap5 = "ProviderShowLocation__gap5__mb0pD";
export var relatedLocationChevron = "ProviderShowLocation__relatedLocationChevron__G7kjN";
export var relatedLocationContainer = "ProviderShowLocation__relatedLocationContainer__v9XZJ";
export var relatedLocationImage = "ProviderShowLocation__relatedLocationImage__VNb1F";
export var relatedLocationLeft = "ProviderShowLocation__relatedLocationLeft__b3pxE";
export var relatedLocationLink = "ProviderShowLocation__relatedLocationLink__J8x_2";
export var relatedLocationRight = "ProviderShowLocation__relatedLocationRight__aZrMs";
export var relatedLocationSummary = "ProviderShowLocation__relatedLocationSummary__U90VE";
export var relatedLocationSummaryAddress = "ProviderShowLocation__relatedLocationSummaryAddress__jP13l";
export var relatedLocationSummaryProvider = "ProviderShowLocation__relatedLocationSummaryProvider__L_W3V";
export var row = "ProviderShowLocation__row__NyvEg";