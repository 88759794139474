// extracted by mini-css-extract-plugin
export var column = "ProviderShowLocations__column__ESkqU";
export var flex = "ProviderShowLocations__flex__Ro_4x";
export var flexColumn = "ProviderShowLocations__flexColumn__xqLm4";
export var gap1 = "ProviderShowLocations__gap1__al5X1";
export var gap2 = "ProviderShowLocations__gap2__HBtOs";
export var gap3 = "ProviderShowLocations__gap3__nQyVm";
export var gap4 = "ProviderShowLocations__gap4__YL95f";
export var gap5 = "ProviderShowLocations__gap5__nJ_b7";
export var locations = "ProviderShowLocations__locations__VrRDP";
export var locationsCount = "ProviderShowLocations__locationsCount__qoaFY";
export var locationsHeader = "ProviderShowLocations__locationsHeader__MVM1N";
export var locationsList = "ProviderShowLocations__locationsList__FBXXQ";
export var locationsPagination = "ProviderShowLocations__locationsPagination__vyb6s";
export var locationsTitle = "ProviderShowLocations__locationsTitle__cLJr4";
export var row = "ProviderShowLocations__row__gFdpz";