// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as IconBuilding from "../../../../../../styleguide/icons/IconBuilding.res.js";
import * as IconCalendar from "../../../../../../styleguide/icons/IconCalendar.res.js";
import * as IconProducts from "../../../../../../styleguide/icons/IconProducts.res.js";
import * as IconUserAvatar from "../../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderShowSidebarStatScss from "./ProviderShowSidebarStat.scss";

var css = ProviderShowSidebarStatScss;

function iconToId(icon) {
  switch (icon) {
    case "TotalDataCenters" :
        return "totalSpace";
    case "YearFounded" :
        return "yearFounded";
    case "NumberEmployees" :
        return "numberEmployees";
    case "Products" :
        return "products";
    
  }
}

function ProviderShowSidebarStat(props) {
  var stat = props.stat;
  var icon = props.icon;
  var slug = props.slug;
  var tmp;
  switch (icon) {
    case "TotalDataCenters" :
        tmp = JsxRuntime.jsx(IconBuilding.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "YearFounded" :
        tmp = JsxRuntime.jsx(IconCalendar.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "NumberEmployees" :
        tmp = JsxRuntime.jsx(IconUserAvatar.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "Products" :
        tmp = JsxRuntime.jsx(IconProducts.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    
  }
  var tmp$1;
  switch (icon) {
    case "TotalDataCenters" :
        tmp$1 = slug !== undefined ? JsxRuntime.jsxs(A.make, {
                href: Routes_Provider.$$Location.index(slug),
                className: css.statLink,
                children: [
                  JsxRuntime.jsx("strong", {
                        children: stat + " "
                      }),
                  "data centers"
                ]
              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("strong", {
                        children: stat + " "
                      }),
                  "year founded"
                ]
              });
        break;
    case "YearFounded" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " "
                    }),
                "year founded"
              ]
            });
        break;
    case "NumberEmployees" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " "
                    }),
                "employees"
              ]
            });
        break;
    case "Products" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " colocation "
                    }),
                "products"
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.statIcon
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: css.statInfo,
                      id: "statInfo"
                    })
              ],
              className: css.statContainer,
              id: iconToId(icon)
            });
}

var make = ProviderShowSidebarStat;

export {
  css ,
  iconToId ,
  make ,
}
/* css Not a pure module */
