// extracted by mini-css-extract-plugin
export var column = "ProviderShowContactForms__column__D4ZKq";
export var field = "ProviderShowContactForms__field__VNMAH";
export var fieldInput = "ProviderShowContactForms__fieldInput___AnKJ";
export var flex = "ProviderShowContactForms__flex__Mqu_I";
export var flexColumn = "ProviderShowContactForms__flexColumn__Tr2Vg";
export var gap1 = "ProviderShowContactForms__gap1__eiE16";
export var gap2 = "ProviderShowContactForms__gap2__DGPcO";
export var gap3 = "ProviderShowContactForms__gap3__YFS53";
export var gap4 = "ProviderShowContactForms__gap4__xXgRX";
export var gap5 = "ProviderShowContactForms__gap5__zIXu3";
export var label = "ProviderShowContactForms__label__OMdEb";
export var required = "ProviderShowContactForms__required__pfglm";
export var row = "ProviderShowContactForms__row__y0AS4";
export var selectInput = "ProviderShowContactForms__selectInput__Se0kw";