// extracted by mini-css-extract-plugin
export var bottomContainer = "ProviderShow__bottomContainer__TQAV6";
export var breadcrumbLink = "ProviderShow__breadcrumbLink__NAQ6e";
export var breadcrumbs = "ProviderShow__breadcrumbs__IWH_0";
export var column = "ProviderShow__column__sYTWe";
export var container = "ProviderShow__container__lCUBR";
export var flex = "ProviderShow__flex__xQoTo";
export var flexColumn = "ProviderShow__flexColumn__WWDQ1";
export var gap1 = "ProviderShow__gap1__ZyJgO";
export var gap2 = "ProviderShow__gap2__nkzo4";
export var gap3 = "ProviderShow__gap3__ckAcb";
export var gap4 = "ProviderShow__gap4__YO64V";
export var gap5 = "ProviderShow__gap5__xN19F";
export var leftContainer = "ProviderShow__leftContainer__FhI0J";
export var mainContent = "ProviderShow__mainContent__oQraF";
export var providerDetails = "ProviderShow__providerDetails__xPLar";
export var rightContainer = "ProviderShow__rightContainer__s8Tya";
export var row = "ProviderShow__row__eaOk4";
export var sidebar = "ProviderShow__sidebar__d28hW";
export var topContainer = "ProviderShow__topContainer__JSsUt";