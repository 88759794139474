// extracted by mini-css-extract-plugin
export var breadcrumbs = "ProviderShowMainContent__breadcrumbs__rTpbW";
export var column = "ProviderShowMainContent__column__tb8gr";
export var contentDetails = "ProviderShowMainContent__contentDetails__UGOAN";
export var contentDetailsTitle = "ProviderShowMainContent__contentDetailsTitle__EYA1d";
export var contentFacilities = "ProviderShowMainContent__contentFacilities__Ruixa";
export var contentFeatures = "ProviderShowMainContent__contentFeatures__kMwZs";
export var contentSummary = "ProviderShowMainContent__contentSummary__OmQHM";
export var contentTabs = "ProviderShowMainContent__contentTabs__ZZNfI";
export var flex = "ProviderShowMainContent__flex__rta73";
export var flexColumn = "ProviderShowMainContent__flexColumn__s_SwZ";
export var gap1 = "ProviderShowMainContent__gap1__uWn9_";
export var gap2 = "ProviderShowMainContent__gap2__pp3VU";
export var gap3 = "ProviderShowMainContent__gap3__Vy_qB";
export var gap4 = "ProviderShowMainContent__gap4__wroMS";
export var gap5 = "ProviderShowMainContent__gap5__CqTkX";
export var mainContentContainer = "ProviderShowMainContent__mainContentContainer__bBarA";
export var providerName = "ProviderShowMainContent__providerName__idBr0";
export var row = "ProviderShowMainContent__row__swe_t";