// extracted by mini-css-extract-plugin
export var column = "ProviderShowContentFeatures__column__xl40S";
export var featureName = "ProviderShowContentFeatures__featureName__xOU0A";
export var flex = "ProviderShowContentFeatures__flex__Rjhn_";
export var flexColumn = "ProviderShowContentFeatures__flexColumn__rTUL2";
export var gap1 = "ProviderShowContentFeatures__gap1__KjErS";
export var gap2 = "ProviderShowContentFeatures__gap2__BfjS9";
export var gap3 = "ProviderShowContentFeatures__gap3__I3PWB";
export var gap4 = "ProviderShowContentFeatures__gap4__fW877";
export var gap5 = "ProviderShowContentFeatures__gap5__qlYoT";
export var providerFeature = "ProviderShowContentFeatures__providerFeature__HhRmy";
export var row = "ProviderShowContentFeatures__row__GQlJE";