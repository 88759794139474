// extracted by mini-css-extract-plugin
export var column = "ProviderShowSidebarStat__column__qYunM";
export var flex = "ProviderShowSidebarStat__flex__w0IFX";
export var flexColumn = "ProviderShowSidebarStat__flexColumn__tUtRc";
export var gap1 = "ProviderShowSidebarStat__gap1__X4p7v";
export var gap2 = "ProviderShowSidebarStat__gap2__HbcdK";
export var gap3 = "ProviderShowSidebarStat__gap3__DgPOZ";
export var gap4 = "ProviderShowSidebarStat__gap4__eSUEb";
export var gap5 = "ProviderShowSidebarStat__gap5__A0sBb";
export var row = "ProviderShowSidebarStat__row__OPVot";
export var statContainer = "ProviderShowSidebarStat__statContainer__p0Wl0";
export var statLink = "ProviderShowSidebarStat__statLink__PDtAt";