// extracted by mini-css-extract-plugin
export var column = "ProviderShowContactForm__column__ISfYm";
export var field = "ProviderShowContactForm__field__TLTz9";
export var fieldInput = "ProviderShowContactForm__fieldInput__xP0r3";
export var fieldRow = "ProviderShowContactForm__fieldRow__nV8MQ";
export var flex = "ProviderShowContactForm__flex__XCvIx";
export var flexColumn = "ProviderShowContactForm__flexColumn__wymdo";
export var formContainer = "ProviderShowContactForm__formContainer__tRAYg";
export var gap1 = "ProviderShowContactForm__gap1__wSNK0";
export var gap2 = "ProviderShowContactForm__gap2__oDVqO";
export var gap3 = "ProviderShowContactForm__gap3__NL_fq";
export var gap4 = "ProviderShowContactForm__gap4__uNs0q";
export var gap5 = "ProviderShowContactForm__gap5___WNr0";
export var label = "ProviderShowContactForm__label__A4n3m";
export var required = "ProviderShowContactForm__required__Xg2gC";
export var row = "ProviderShowContactForm__row__AJaPw";
export var selectInput = "ProviderShowContactForm__selectInput__LBID8";