// extracted by mini-css-extract-plugin
export var column = "ProviderShowContact__column__RW6C7";
export var contactEnvelope = "ProviderShowContact__contactEnvelope__nJ34f";
export var contactJobTitle = "ProviderShowContact__contactJobTitle__FDkPq";
export var contactMessage = "ProviderShowContact__contactMessage__lmRhg";
export var contactMessageSub = "ProviderShowContact__contactMessageSub__poS0x";
export var contactName = "ProviderShowContact__contactName__vHwaK";
export var contactPerson = "ProviderShowContact__contactPerson__TTIZg";
export var contactPersonImage = "ProviderShowContact__contactPersonImage__DwlI9";
export var contactSubmitted = "ProviderShowContact__contactSubmitted__v5ISE";
export var contactSubtitle = "ProviderShowContact__contactSubtitle__qQQlY";
export var contactSummary = "ProviderShowContact__contactSummary__eTae2";
export var contactTitle = "ProviderShowContact__contactTitle__R0jJx";
export var envelopeImage = "ProviderShowContact__envelopeImage__HXabc";
export var flex = "ProviderShowContact__flex__WPY4r";
export var flexColumn = "ProviderShowContact__flexColumn__Bk1pu";
export var gap1 = "ProviderShowContact__gap1__ZvQ47";
export var gap2 = "ProviderShowContact__gap2__zHk_N";
export var gap3 = "ProviderShowContact__gap3__Il8jL";
export var gap4 = "ProviderShowContact__gap4__ZVYxt";
export var gap5 = "ProviderShowContact__gap5__kP83X";
export var providerContactContainer = "ProviderShowContact__providerContactContainer__DRBxe";
export var providerName = "ProviderShowContact__providerName__JoE1J";
export var row = "ProviderShowContact__row__nPJqE";
export var salesManagerImage = "ProviderShowContact__salesManagerImage__tqQhq";