// extracted by mini-css-extract-plugin
export var column = "ProviderShowContentTabs__column__PXauM";
export var flex = "ProviderShowContentTabs__flex__XQgq_";
export var flexColumn = "ProviderShowContentTabs__flexColumn__m7f3Q";
export var gap1 = "ProviderShowContentTabs__gap1__ySn8M";
export var gap2 = "ProviderShowContentTabs__gap2__bQlmp";
export var gap3 = "ProviderShowContentTabs__gap3__XNWUl";
export var gap4 = "ProviderShowContentTabs__gap4__odVun";
export var gap5 = "ProviderShowContentTabs__gap5__Yk79l";
export var row = "ProviderShowContentTabs__row__y9tfd";
export var tab = "ProviderShowContentTabs__tab__zkhcG";
export var tabActive = "ProviderShowContentTabs__tabActive__NIiAC";